<template>
  
    <v-sheet>
      <!--
        <v-file-input label="Hola" @change="setImage($event)"></v-file-input>
        -->
        <v-row  v-if="!edit_image">
          <v-col>
            <v-card>
                <ToolbarMultiIcons 
                      :titulo="'Imagen'" 
                      :actions="[
                        {value: 'edit_image',name:'Editar imagen',icon:'mdi-pencil'},
                        {value: 'delete_image',name:'Eliminar imagen',icon:'mdi-delete'},
                        {value: 'upload_images',name:'Agregar despues',icon:'mdi-upload'},
                      
                      ]"  
                      @edit_image="edit_image=true;initialImageSetUp(image)"
                      @delete_image="$emit('delete_image')"
                      @upload_images="$emit('upload_images')"
                      
                  />
                <v-img :src="image" contain :aspect-ratio="16/9" width="100%" height="400" >

                </v-img>
            </v-card >
          </v-col>
        </v-row>
        <v-row v-else>
>
          <v-col>
            <v-card>
                <ToolbarMultiIcons 
                    :titulo="'Editar imagen'"
                    @delete_image="$emit('delete_image')"
                    @move_image="$emit('move_image')"
                    @zoom_in="zoom(0.2)"
                    @zoom_out="zoom(-0.2)"
                    @rotate_right="rotate(90)"
                    @rotate_left="rotate(-90)"
                    @move_left="move(10, 0)"
                    @move_right="move(-10, 0)"
                    @move_up="move(0, -10)"
                    @move_down="move(0, 10)"
                    @reset="reset()"
                    @save="submitImage()"
                    :actions="actions" 
                  />
                <v-card-text>
                  <v-row>
                    <v-col class="">
                      <vue-cropper
                            ref="cropper"
                           
                            :src="imgSrc"
                            preview=".preview"
                          />
                      

                    </v-col>

                  </v-row>
                  

                </v-card-text>
              </v-card>
          </v-col>
        </v-row>
         
        
        
       
    </v-sheet>
  
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import ToolbarMultiIcons from "../atomos/ToolbarMultiIcons.vue"

export default {
  components: {
    VueCropper,
    ToolbarMultiIcons,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    image: {
      required: true
    },

  },
  data() {
    return {
      imgSrc: "/assets/images/berserk.jpg",
      cropImg: "",
      data: null,
      filename: "",
      mimeType: "",
      isAvatarUploadDialogOpen: false,
      edit_image: false,
      actions: [
        
        {
          value: "move_image",
          name: "Mover imagen",
          icon: "mdi-image-move",
        },
        {
          value: "delete_image",
          name: "Eliminar",
          icon: "mdi-delete",
        },
        {
          value: "zoom_in",
          name: "Aumentar",
          icon: "mdi-magnify-plus-outline",
        },
        {
          value: "zoom_out",
          name: "Disminuir",
          icon: "mdi-magnify-minus-outline",
        },
        {
          value: "rotate_right",
          name: "Girar derecha",
          icon: "mdi-reload",
        },
        {
          value: "rotate_left",
          name: "Girar izquierda",
          icon: "mdi-restore",
        },
        {
          value: "move_left",
          name: "Mover izquierda",
          icon: "mdi-arrow-left",
        },
        {
          value: "move_right",
          name: "Mover derecha",
          icon: "mdi-arrow-right",
        },
        {
          value: "move_up",
          name: "Mover arriba",
          icon: "mdi-arrow-up",
        },
        {
          value: "move_down",
          name: "Mover arriba",
          icon: "mdi-arrow-down",
        },
        {
          value: "reset",
          name: "Re-establecer",
          icon: "mdi-reload-alert",
        },
        {
          value: "save",
          name: "Guardar",
          icon: "mdi-check",
        },
      ]
    };
  },
  watch: {
    image(value) {

      this.initialImageSetUp(value)
    }
  },
  mounted() {
    this.initialImageSetUp(this.image);
    console.log('xs')
  },
  methods: {
    initialImageSetUp(value) {

      if (!value) {
        this.cropImg = ""
        this.$emit('close', true)
        return;
      }
      this.isAvatarUploadDialogOpen = true
      this.filename = value.name
      this.mimeType = value.type
      this.setImage(value)
    },
async dataURLToFile(imageString, filename, mimeType) {

    const res = await fetch(imageString);
    const blob = await res.blob();
    return new File([blob], filename, { type: mimeType });
  },
    async submitImage() {
      try {
        await this.cropImage();
        const imageFileResponse = await this.dataURLToFile(this.cropImg, this.filename, this.mimeType)
        this.$emit('save_image_edited', imageFileResponse)
        this.isAvatarUploadDialogOpen = false;
      } catch (error) {
        console.log(error);
      }
      

      this.edit_image = false;

    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    // flipX() {
    //   const dom = this.$refs.flipX;
    //   let scale = dom.getAttribute("data-scale");
    //   scale = scale ? -scale : -1;
    //   this.$refs.cropper.scaleX(scale);
    //   dom.setAttribute("data-scale", scale);
    // },
    // flipY() {
    //   const dom = this.$refs.flipY;
    //   let scale = dom.getAttribute("data-scale");
    //   scale = scale ? -scale : -1;
    //   this.$refs.cropper.scaleY(scale);
    //   dom.setAttribute("data-scale", scale);
    // },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    async setImage(file) {
      console.log(file);
     
      this.imgSrc = file;
      this.$refs.cropper.replace(file);

      await this.$store.dispatch('utils/sleep',2000);
      this.zoom(-0.2)
      /*
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          console.log(event);
          console.log('xs')
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      */
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<style>
input[type="file"] {
  display: none;
}

.cropped-image {
  padding: 0 .8rem;
}

.img-cropper {
  max-height: 400px;
  overflow: hidden;
}


</style>