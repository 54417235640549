<template>
    <v-app>
        <v-main>
            <IniciarCiclo/>
        </v-main>
    </v-app>
</template>
<script>
import IniciarCiclo from '../components/formularios/IniciarCiclo'
export default {
    components: {
        IniciarCiclo
    },
    data(){
        return{

        }
    },
    async created(){
         let token =  await this.$store.dispatch('token_caso')
            console.log(token)
            console.log('xds')
    }
}
</script>